// export default Resume;
import React, { useEffect } from "react";
import { DocumentTextIcon } from "@heroicons/react/solid";
import particlesJS from "particles.js";
import particlesConfig from "../particles-config.json";

const Resume = () => {
  // useEffect(() => {
  //   window.particlesJS("particles-js", {
  //     ...particlesConfig,
  //     particles: {
  //       ...particlesConfig.particles,
  //       size: {
  //         value: 3,
  //       },
  //     },
  //   });
  // }, []);

  const styles = {
    madeWithLove: {
      marginTop: "20px",
      fontSize: "1rem",
      color: "gray",
      fontStyle: "italic",
      textAlign: "center",
    },
    boldText: {
      fontWeight: "bold",
    },
    iframe: {
      height: "85vh",
      width: "700px",
    },
  };

  return (
    <div className="center-content">
      {/* <div id="particles-js"></div> */}
      <div className="content-wrapper relative z-1">
        <div className="text-center mb-4">
          <br />

        </div>
        <div className="center-iframe">
          <iframe
            src="https://drive.google.com/file/d/1Fff241DMsnjXRtW5MYql5EdUZgdQYcL7/preview"
            
            style={styles.iframe}
            allow="autoplay"
          ></iframe>
        </div>
        <div style={styles.madeWithLove}>
          Made with <span role="img" aria-label="heart">❤️</span> by{" "}
          <span style={styles.boldText}>Anastasiya </span> | 2024
        </div>
      </div>
    </div>
  );
};

export default Resume;
