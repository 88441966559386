import { CodeIcon } from "@heroicons/react/solid";
import React from "react";
import { projects } from "../data";

export default function Projects() {
  const groupedProjects = projects.reduce((acc, project) => {
    if (!acc[project.category]) {
      acc[project.category] = [];
    }
    acc[project.category].push(project);
    return acc;
  }, {});

  return (
    <section id="projects" className="text-gray-400 bg-gray-900 body-font">
      <div className="container px-5 py-10 mx-auto text-center lg:px-40">
        <div className="flex flex-col w-full mb-20">
          <CodeIcon className="mx-auto inline-block w-10 mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
            Projects
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            I enjoy learning outside the classroom and applying my skills in practice. These are my favorite projects I have built in the recent past.
          </p>
        </div>
        {Object.entries(groupedProjects).map(([category, projectsInCategory]) => (
          <div key={category} className="mb-8">
            
            <div className={`flex flex-wrap -m-4 ${projectsInCategory.length === 1 ? 'justify-center' : ''}`}>
              {projectsInCategory.map((project, index) => (
                <div
                  key={project.image}
                  className={`sm:w-1/2 w-full p-4`}
                >
                  <div className="flex relative h-[280px]">
                    <a href={project.link} target="_blank">
                      <img
                        alt="gallery"
                        className="absolute inset-0 w-full h-full object-cover object-center"
                        src={project.image}
                      />
                      <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100 h-full">
                        <h2 className="tracking-widest text-sm title-font font-medium text-green-400 mb-1">
                          {project.subtitle}
                        </h2>
        
                        <h1 className="title-font text-lg font-medium text-white mb-3">
                          {project.title}
                        </h1>
                        <p className="leading-relaxed">{project.description}</p>
                      </div></a>
                  </div>

                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
